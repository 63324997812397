
import { Container, Row, Col } from 'react-bootstrap';
import './SecondaryActions.css';

import { Facebook, Linkedin, Instagram, Youtube, Pinterest, Tiktok, Twitter } from 'react-bootstrap-icons';

function SecondaryActions(props) {
    return (
        <Container fluid className="SecondaryActions">
            <Row className="justify-content-center pb-3 px-3">
                <Col className="col-md-auto px-0">
                    <a className="nounderline link-secondary" href="https://www.facebook.com/LatisLogistics">
                    <div className="ikona">
                        <img src="./facebook1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="30" width="auto"></img>
                            {/* <div className="ActionName">Email</div> */}
                    </div>
                    </a>
                </Col>
                <Col className="col-md-auto px-0">
                    <a className="nounderline link-secondary" href="https://www.instagram.com/latis_logistics/">
                    <div className="ikona">
                        <img src="./instagram1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="30" width="auto"></img>
                            {/* <div className="ActionName">Email</div> */}
                        </div>    
                    </a>
                </Col>
                <Col className="col-md-auto px-0">
                    <a className="nounderline link-secondary" href="https://www.linkedin.com/company/latis-logistics/">
                    <div className="ikona">
                        <img src="./linkedin1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="30" width="auto"></img>
                            {/* <div className="ActionName">Email</div> */}
                        </div>
                    </a>
                </Col>
                {/* <Col className="col-md-auto px-0">
                    <a className="nounderline link-secondary" href="https://www.youtube.com/channel/UC6EkUQq3K1c3E8LbvoudJng">
                    <div className="ikona">
                        <img src="./youtube1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="30" width="auto"></img>
                            {/* <div className="ActionName">Email</div> 
                        </div>
                    </a>
                </Col> */}
                {/* <Col className="col-md-auto px-0">
                    <a className="nounderline link-secondary" href="https://pl.pinterest.com/NectarinaAdvertisingPoint/_created">
                    <div className="ikona">
                        <img src="./pinterest1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="30" width="auto"></img>
                            {/* <div className="ActionName">Email</div> 
                        </div>
                    </a>
                </Col> */}
                {/* <Col className="col-md-auto px-0">
                    <a className="nounderline link-secondary" href="https://pl.tiktok.com/">
                    <div className="ikona">
                        <img src="./tiktok1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="30" width="auto"></img>
                            {/* <div className="ActionName">Email</div>
                        </div>
                    </a>
                </Col> */}
                <Col className="col-md-auto px-0">
                    <a className="nounderline link-secondary" href="https://twitter.com/latislogistics">
                    <div className="ikona">
                        <img src="./twitter1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="30" width="auto"></img>
                            {/* <div className="ActionName">Email</div> */}
                        </div>
                    </a>
                </Col>
            </Row>
        </Container>
    )
}

export default SecondaryActions;