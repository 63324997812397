
import { Container, Row, Col } from 'react-bootstrap';
import './PrimaryActions.css';

function PrimaryActions(props) {
    return (
        // <Container>
            <Row className="justify-content-center px-4">
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="tel:+48 728 817 31">
                        <div className="img-fluid">
                            <img src="./mobile1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Mobile</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="tel:+48 587 322 203">
                        <div>
                        <img src="./office1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Office</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="mailto:akonopko@latis.pl">
                        <div>
                        <img src="./email1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Email</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="https://www.google.pl/maps/place/Latis+Sp.+z+o.o./@54.5288086,18.512317,17z/data=!3m1!4b1!4m5!3m4!1s0x46fda6dcdb8217c3:0x3d1db6577f0f757f!8m2!3d54.5288155!4d18.5145157">
                        <div >
                        <img src="./map1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Map</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="https://www.latis.pl">
                        <div >
                        <img src="./website1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Website</div> */}
                        </div>
                    </a>
                </Col>
            </Row>
        // </Container>
    )
}

export default PrimaryActions;