
import "./CardHeader.css"
import {Container, Row, Col } from 'react-bootstrap'

function CardHeader() {

    return (
        <header className="header p-3">
            <img style={{maxWidth: "120px", filter: "invert(23%) sepia(16%) saturate(2925%) hue-rotate(229deg) brightness(95%) contrast(96%)"}} src="./latis.svg" className="max-width: 250px" alt="Latis"></img>
        </header>
        
    )

}

export default CardHeader;